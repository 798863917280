<template>
  <InternalNavBar />
  <div class="row" style="height: 10vh; padding-top: 18vh; justify-content: center">
    <p
        class="text-h4"
        style="font-family: Lato; text-align: left; margin-bottom: 0"
    >
      The Shop is coming soon!
    </p>
  </div>
</template>

<script>
  import InternalNavBar from "@/components/internal-navbar";

  export default {
    name: "Shop",
    components: {
      InternalNavBar,
    }
  }
</script>


<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gentium+Basic:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@font-face {
  font-family: "Farray";
  src: url("../assets/fonts/FARRAY.otf");
}
.text-caption {
  margin-bottom: 0vh;
}

</style>

